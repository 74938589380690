// select2
import "admin-lte/plugins/select2/js/select2.full.min.js"
import "admin-lte/plugins/select2/css/select2.min.css"
import "admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css"

// flatpickr
import "flatpickr/dist/flatpickr.min.js"
import "flatpickr/dist/l10n/ja.js"
import "flatpickr/dist/flatpickr.min.css"

// jpostal
import "jquery-jpostal-ja/jquery.jpostal.js"

document.addEventListener('turbolinks:load', () => {
    
  $('.select2').select2({
    theme: 'bootstrap4',
    placeholder: "選択してください"
  });

  flatpickr(".datepicker", {
    locale: 'ja'
  });
  
  $('#customer_zip1').jpostal({
    trigger : {
      '#customer_address1': true
    },
    postcode : [
      '#customer_zip1'
    ],
    address : {
      '#customer_prefecture1' : '%3',
      '#customer_city1' : '川崎市',
      '#customer_address1' : "%4%5"
    }
  });

  $("#customer_address1").change(function () {
    $("#customer_address1").val($("#customer_address1").val().replace('川崎市', ''))
  });

  $('.copy-address1').on('click', function() {
    $('#customer_zip2').val($('#customer_zip1').val());
    $('#customer_prefecture2').val($('#customer_prefecture1').val());
    $('#customer_city2').val($('#customer_city1').val());
    $('#customer_invoice_address').val($('#customer_address1').val());
  })
});