// summernote
import "summernote/dist/summernote-bs4.js"
import "summernote/dist/summernote-bs4.css"
import "summernote/dist/lang/summernote-ja-JP.min.js"

document.addEventListener('turbolinks:load', () => {

	// アコーディオン表示
  $('.s_02 .accordion_one .accordion_header').click(function(){
    $(this).next('.accordion_inner').slideToggle();
    $(this).toggleClass("open");
    $('.s_02 .accordion_one .accordion_header').not($(this)).next('.accordion_one .accordion_inner').slideUp();
    $('.s_02 .accordion_one .accordion_header').not($(this)).removeClass("open");
  });

	// carrierwaveの画像をsummernoteに表示
  function sendFile(file){
    const data = new FormData();
    data.append('upload[image]', file);
    $.ajax({
      data: data,
      type: 'POST',
      url: '/system_admin/uploads',
      cache: false,
      contentType: false,
      processData: false,
      success: function(data) {
        const img = document.createElement('IMG');
        img.src = data.url;
        console.log(data);
        img.setAttribute('id', "sn-image-#{data.upload_id}");
        $('#summernote').summernote('insertNode', img);
      }
    });
  }
		
  function deleteFile(file_id){
    $.ajax({
      type: 'DELETE',
      url: "/system_admin/uploads/#{file_id}",
      cache: false,
      contentType: false,
      processData: false
    });
  }
		
  $('#summernote').summernote({
    placeholder: 'ここに入力',
    tabsize: 2,
    height: 120,
    fontNames:["YuGothic","Yu Gothic","Hiragino Kaku Gothic Pro","Meiryo","sans-serif","Arial","Arial Black","Comic Sans MS","Courier New","Helvetica Neue","Helvetica","Impact","Lucida Grande","Tahoma","Times New Roman","Verdana"],
    lang:"ja-JP",
    toolbar: [
      ['font', ['bold', 'underline', 'clear']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'picture']],
      ['view', ['fullscreen', 'codeview', 'help']]
    ],
    callbacks: {
      onImageUpload: function(files){
        sendFile(files[0])
        $('#summernote');
      },
      onMediaDelete: function(target, editor, editable){
        upload_id = target[0].id.split('-').slice(-1)[0];
        console.log(upload_id)
        if (!!upload_id)
          deleteFile(upload_id)
        target.remove()
      }
    }
  });
});