import Cookies from 'js-cookie'

document.addEventListener('turbolinks:load', () => {
    
  $('.loading').on("click", function() {
		$("#overlay").fadeIn(500);
    var intervalId;
    intervalId = setInterval(function() {
      if (Cookies.get('load')) {
        $("#overlay").fadeOut(500);;
        clearInterval(intervalId);
        Cookies.remove('load');
      }
    }, 1000);
  });
});
